<template>
  <table class="mt-4 col-2 table h-100 align-self-center">
    <thead>
      <tr>
        <th>{{ $t("ENERGY_MANAGEMENT.HEADER_TOTAL") }}</th>
        <th>{{ $t("ENERGY_MANAGEMENT.HEADER_VALUE") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ $t("ENERGY_MANAGEMENT.TOTAL_REAL") }}</td>
        <td>{{ real }}</td>
      </tr>
      <tr>
        <td>{{ $t("ENERGY_MANAGEMENT.TOTAL_PREDICTED") }}</td>
        <td>{{ predicted }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TotalTable",
  props: {
    real: {
      required: true,
    },
    predicted: {
      required: true,
    },
  },
};
</script>
