const ManagementUtils = {
  getGranularity(period) {
    if (period.month.length > 0 && period.day.length > 0) {
      return "day";
    }
    if (period.month.length > 0) {
      return "month";
    }
    return "year";
  },
  getLanguage() {
    let language = localStorage.getItem("language");
    language = language === "ro" ? "ro-RO" : "en-US";
    return language;
  },
  getExportedParsedDate(date) {
    if (date) {
      return new Date(date).toLocaleString(this.getLanguage()).replace(",", "");
    }
    return "";
  },
  getDateUTC(value) {
    const date = new Date(value);
    const timeDiff = date.getTimezoneOffset() * 60000;
    let adjustedDate = new Date(date.valueOf() + timeDiff);
    adjustedDate = adjustedDate
      .toLocaleDateString(this.getLanguage(), {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replaceAll(",", "");

    return adjustedDate;
  },
  onExportSynchronizedChartsYear(
    cui,
    name,
    thisYearSeries,
    lastYearSeries,
    thisYear,
    lastYear
  ) {
    let csv = `${cui},${name}\n`;
    csv += `${thisYear}\n`;
    csv += ",Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec,Total\n";
    // Real
    csv += thisYearSeries[0].name + ",";
    csv += thisYearSeries[0].data.join(",") + ",";
    csv += thisYearSeries[0].data.reduce((prev, current) => {
      return Number(prev) + Number(current);
    }, 0);

    csv += "\n";

    // Predicted
    csv += thisYearSeries[1].name + ",";
    csv += thisYearSeries[1].data.join(",") + ",";
    csv += thisYearSeries[1].data.reduce((prev, current) => {
      return Number(prev) + Number(current);
    }, 0);

    csv += "\n";

    csv += `\n${lastYear}\n`;
    csv += ",Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec,Total\n";

    // Real
    csv += lastYearSeries[0].name + ",";
    csv += lastYearSeries[0].data.join(",") + ",";
    csv += lastYearSeries[0].data.reduce((prev, current) => {
      return Number(prev) + Number(current);
    }, 0);

    csv += "\n";

    // Predicted
    csv += lastYearSeries[1].name + ",";
    csv += lastYearSeries[1].data.join(",") + ",";
    csv += lastYearSeries[1].data.reduce((prev, current) => {
      return Number(prev) + Number(current);
    }, 0);

    csv += "\n";

    this.exportCsv(name, csv);
  },
  onExportSynchronizedChartsMonth(cui, name, data) {
    let csv = `${cui},${name}\n`;
    csv += "Date,Value (MW),Date,Value (MW),Date,Value (MW)\n";
    let totalCurrent = 0,
      totalLast = 0,
      totalYear = 0;
    for (let i = 0; i < data.current.length; i++) {
      let current = data.current[i];
      let last = data.last[i];
      let year = data.year[i];

      totalCurrent += Number(current[1] != null ? current[1] : 0);
      totalLast += Number(last[1] != null ? last[1] : 0);
      totalYear += Number(year[1] != null ? year[1] : 0);

      csv +=
        this.getExportedParsedDate(current[2]) +
        "," +
        (current[1] != null ? current[1] : "") +
        ",";
      csv +=
        this.getExportedParsedDate(last[2]) +
        "," +
        (last[1] != null ? last[1] : "") +
        ",";
      csv +=
        this.getExportedParsedDate(year[2]) +
        "," +
        (year[1] != null ? year[1] : "");
      csv += "\n";
    }

    csv += "\nTotal \n";
    csv += `,${totalCurrent},,${totalLast},,${totalYear}`;

    this.exportCsv(name, csv);
  },
  onExportChartsDay(cui, name, data) {
    let csv = `${cui},${name}\n`;
    let total = 0;
    csv += "Date,Value (MW)\n";
    for (let i = 0; i < data.length; i++) {
      total += data[i][1];
      csv += this.getDateUTC(data[i][0]) + "," + data[i][1] + "\n";
    }
    csv += "\nTotal,";
    csv += total;

    this.exportCsv(name, csv);
  },
  exportCsv(name, csv) {
    const anchor = document.createElement("a");
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
    anchor.target = "_blank";
    anchor.download = `${name}.csv`;
    anchor.click();
  },
};

export default ManagementUtils;
