<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>

    <v-card-text class="px-10">
      <v-container class="px-0">
        <!-- COMPANY NAME -->
        <v-row v-if="details.hasOwnProperty('companyName')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.CLIENT_NAME')"
            class="mb-3 p-0"
          >
            <b-form-input v-model="details.companyName"></b-form-input>
          </b-input-group>
        </v-row>
        <!-- COMPANY CUI -->
        <v-row v-if="details.hasOwnProperty('companyCui')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.CLIENT_CUI')"
            class="mb-3 p-0"
          >
            <b-form-input v-model="details.companyCui"></b-form-input>
          </b-input-group>
        </v-row>
        <!-- ENERGY TYPE -->
        <v-row v-if="details.hasOwnProperty('energyType')">
          <b-input-group
            data-app
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.ENERGY_TYPE')"
            class="mb-3 p-0"
          >
            <v-autocomplete
              class="input-group custom-select"
              v-model="details.energyType"
              :items="energyTypes"
              autocomplete="not-today"
              solo
              flat
              dense
            ></v-autocomplete>
          </b-input-group>
        </v-row>
        <!-- EVENT TYPE -->
        <v-row v-if="details.hasOwnProperty('eventType')">
          <b-input-group
            data-app
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.EVENT_TYPE')"
            class="mb-3 p-0"
          >
            <v-autocomplete
              class="input-group custom-select"
              v-model="details.eventType"
              :items="eventTypes"
              autocomplete="not-today"
              solo
              flat
              dense
            ></v-autocomplete>
          </b-input-group>
        </v-row>
        <!-- REASON -->
        <v-row v-if="details.hasOwnProperty('reason')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.REASON')"
            class="mb-3 p-0"
          >
            <b-form-input v-model="details.reason"></b-form-input>
          </b-input-group>
        </v-row>
        <!-- VALUE -->
        <v-row v-if="details.hasOwnProperty('value')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.VALUE')"
            class="mb-3 p-0"
          >
            <b-form-input v-model="details.value"></b-form-input>
          </b-input-group>
        </v-row>
        <!-- START DATE -->
        <v-row v-if="details.hasOwnProperty('startDate')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.START_DATE')"
            class="mb-3 p-0"
          >
            <b-form-datepicker v-model="details.startDate"></b-form-datepicker>
            <b-form-timepicker
              v-model="details.startHour"
              locale="en"
            ></b-form-timepicker>
          </b-input-group>
        </v-row>
        <!-- END DATE -->
        <v-row v-if="details.hasOwnProperty('endDate')">
          <b-input-group
            :prepend="$t('EVENT_MANAGEMENT.TABLE_HEADER.END_DATE')"
            class="mb-3 p-0"
          >
            <b-form-datepicker v-model="details.endDate"></b-form-datepicker>
            <b-form-timepicker
              v-model="details.endHour"
              locale="en"
            ></b-form-timepicker>
          </b-input-group>
        </v-row>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <button class="btn btn-secondary mr-5" @click="$emit('close')">
        {{ $t("EVENT_MANAGEMENT.CANCEL") }}
      </button>
      <button class="btn btn-primary" @click="$emit('save')">
        {{ $t("EVENT_MANAGEMENT.SAVE") }}
      </button>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EventModal",
  components: {},
  props: {
    title: String,
    details: {
      type: Object,
    },
  },
  computed: {},
  emits: ["close", "save"],
  data() {
    return {
      eventTypes: [],
      energyTypes: [],
    };
  },
  methods: {},
  mounted() {
    this.eventTypes = [
      this.$t("EVENT_MANAGEMENT.EVENT_TYPES.POWER_OUTAGE"),
      this.$t("EVENT_MANAGEMENT.EVENT_TYPES.WEATHER"),
      this.$t("EVENT_MANAGEMENT.EVENT_TYPES.WIND"),
      this.$t("EVENT_MANAGEMENT.EVENT_TYPES.OTHER"),
    ];

    this.energyTypes = [
      this.$t("FILTER.ENERGY.ENERGY"),
      this.$t("FILTER.ENERGY.GAS"),
      // this.$t("FILTER.ENERGY.RENEWABLE"),
    ];
  },
};
</script>
<style>
.input-group-prepend {
  width: 30%; /*adjust as needed*/
}

.input-group-prepend .input-group-text {
  width: 100%;
  overflow: hidden;
}
</style>
