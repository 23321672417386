import ApiService from "./api.service";

const ENERGY = {
  Energy: 0,
  Electric: 0,
  Gas: 1,
  Solar: 2,
};

const EventsService = {
  getEvents(filter) {
    return new Promise((resolve, reject) => {
      filter.type = ENERGY[filter.type];
      ApiService.post("/events", filter)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addEvent(event) {
    return new Promise((resolve, reject) => {
      event.energyType = ENERGY[event.energyType];
      ApiService.post("/events/add", event)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteEvent(id) {
    return new Promise((resolve, reject) => {
      ApiService.post("/events/delete", { id })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateEvent(event) {
    event.energyType = ENERGY[event.energyType];
    return new Promise((resolve, reject) => {
      ApiService.post("/events/update", { event })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addPublicHolidays(holidays) {
    return new Promise((resolve, reject) => {
      ApiService.post("/public-holidays/add", { holidays })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removePublicHoliday(holiday) {
    return new Promise((resolve, reject) => {
      ApiService.post("/public-holidays/remove", { holiday })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getPublicHolidays(year) {
    return new Promise((resolve, reject) => {
      ApiService.post("/public-holidays", { year })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default EventsService;
