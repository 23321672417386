<template>
  <div>
    <div class="d-flex justify-content-around">
      <div class="col mt-10">
        <apexchart
          style="height: 200px"
          type="line"
          height="200"
          :options="chartOptionsCurrent"
          :series="seriesCurrent"
        ></apexchart>
        <div v-if="solarRadiation">
          <apexchart
            style="height: 200px"
            type="line"
            height="200"
            :options="chartOptionsSolar"
            :series="seriesSolar"
          ></apexchart>
        </div>
        <apexchart
          style="height: 200px"
          type="line"
          height="200"
          :options="chartOptionsLast"
          :series="seriesLast"
        ></apexchart>
        <apexchart
          style="height: 200px"
          type="line"
          height="200"
          :options="chartOptionsYear"
          :series="seriesYear"
        ></apexchart>
      </div>
      <table class="mt-10 col-2 table h-100 align-self-center">
        <thead>
          <tr>
            <th>{{ $t("ENERGY_MANAGEMENT.HEADER_TOTAL") }}</th>
            <th>{{ $t("ENERGY_MANAGEMENT.HEADER_VALUE") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ nameCurrent }}</td>
            <td>{{ total("current") }}</td>
          </tr>
          <tr v-if="solarRadiation">
            <td>{{ shortDate(seriesSolar) }}</td>
            <td>{{ total("solar") }}</td>
          </tr>
          <tr>
            <td>{{ nameLast }}</td>
            <td>{{ total("last") }}</td>
          </tr>
          <tr>
            <td>{{ nameYear }}</td>
            <td>{{ total("year") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ChartUtils from "./utils";

export default {
  name: "PredictionChartsMonth",
  components: {},
  props: {
    height: {
      type: Number,
      validator: (value) => value > 0,
    },
    chartData: {
      required: true,
    },
    events: {
      required: true,
    },
    solarRadiation: {
      default: false,
    },
  },

  methods: {
    getGlobalYMin() {
      let min = 0;
      for (let i = 0; i < this.chartData.current.length; i++) {
        if (
          this.chartData.current[i][1] != null &&
          min > Number(this.chartData.current[i][1])
        ) {
          min = Number(this.chartData.current[i][1]);
        }
        if (
          this.chartData.last[i][1] != null &&
          min > Number(this.chartData.last[i][1])
        ) {
          min = Number(this.chartData.last[i][1]);
        }
        if (
          this.chartData.year[i][1] != null &&
          min > Number(this.chartData.year[i][1])
        ) {
          min = Number(this.chartData.year[i][1]);
        }
      }
      return min;
    },
    getGlobalYMax() {
      let max = this.chartData.length > 0 ? this.chartData.current[0][1] : 0;
      for (let i = 0; i < this.chartData.current.length; i++) {
        if (
          this.chartData.current[i][1] != null &&
          max < Number(this.chartData.current[i][1])
        ) {
          max = Number(this.chartData.current[i][1]);
        }
        if (
          this.chartData.last[i][1] != null &&
          max < Number(this.chartData.last[i][1])
        ) {
          max = Number(this.chartData.last[i][1]);
        }
        if (
          this.chartData.year[i][1] != null &&
          max < Number(this.chartData.year[i][1])
        ) {
          max = Number(this.chartData.year[i][1]);
        }
      }
      return max;
    },
    total(type) {
      let sum = 0;
      for (let i = 0; i < this.chartData[type].length; i++) {
        sum += Number(this.chartData[type][i][1]);
      }
      return Number(sum).toLocaleString(ChartUtils.language, {
        maximumFractionDigits: 2,
      });
    },
    shortDate(data) {
      if (!data || data.length == 0) {
        return "-";
      }
      // first not null date
      let index = -1;
      for (let i = 0; i < data.length; i++) {
        if (data[i][2]) {
          index = i;
          break;
        }
      }
      if (index == -1) {
        return "-";
      }
      return new Date(data[index][2]).toLocaleString(ChartUtils.language, {
        year: "numeric",
        month: "short",
      });
    },
  },
  computed: {
    nameCurrent() {
      return this.shortDate(this.chartData.current);
    },
    nameLast() {
      return this.shortDate(this.chartData.last);
    },
    nameYear() {
      return this.shortDate(this.chartData.year);
    },
    seriesCurrent() {
      return [{ name: this.nameCurrent, data: this.chartData.current }];
    },
    seriesLast() {
      return [{ name: this.nameLast, data: this.chartData.last }];
    },
    seriesYear() {
      return [{ name: this.nameYear, data: this.chartData.year }];
    },
    seriesSolar() {
      return this.solarRadiation
        ? [
            {
              name: this.$t("ENERGY_MANAGEMENT.SOLAR_RADIATION"),
              data: this.chartData.solar,
            },
          ]
        : null;
    },
    chartOptionsCurrent() {
      let min = this.getGlobalYMin();
      let max = this.getGlobalYMax();
      return ChartUtils.getLineChartOptions(
        "current",
        "energy",
        this.nameCurrent,
        this.chartData.current,
        this.events.current,
        min,
        max,
        false
      );
    },
    chartOptionsLast() {
      let min = this.getGlobalYMin();
      let max = this.getGlobalYMax();
      return ChartUtils.getLineChartOptions(
        "last",
        "energy",
        this.nameLast,
        this.chartData.last,
        this.events.last,
        min,
        max,
        false
      );
    },
    chartOptionsYear() {
      let min = this.getGlobalYMin();
      let max = this.getGlobalYMax();
      return ChartUtils.getLineChartOptions(
        "year",
        "energy",
        this.nameYear,
        this.chartData.year,
        this.events.year,
        min,
        max,
        false
      );
    },
    chartOptionsSolar() {
      return this.solarRadiation
        ? ChartUtils.getSolarChartOptions(
            "solar",
            "energy",
            this.$t("ENERGY_MANAGEMENT.SOLAR_RADIATION"),
            this.chartData.solar,
            this.events.solar
          )
        : null;
    },
  },
};
</script>
