<template>
  <div>
    <div class="d-flex justify-content-around">
      <div class="col">
        <!-- consume this year -->
        <apexchart
          type="bar"
          :options="thisYearChartOptions"
          :series="thisYearSeries"
          class="w-100"
          :height="height"
        ></apexchart>
      </div>
      <TotalTable
        :real="total(0, thisYearSeries)"
        :predicted="total(1, thisYearSeries)"
      />
    </div>
    <div class="d-flex justify-content-around">
      <div class="col">
        <!-- consume last year -->
        <apexchart
          type="bar"
          :options="lastYearChartOptions"
          :series="lastYearSeries"
          class="w-100"
          :height="height"
        ></apexchart>
      </div>
      <TotalTable
        :real="total(0, lastYearSeries)"
        :predicted="total(1, lastYearSeries)"
      />
    </div>
  </div>
</template>

<script>
import ChartUtils from "./utils";
import TotalTable from "@/view/content/charts/TotalTable.vue";

export default {
  name: "PredictionChartsYear",
  components: { TotalTable },
  props: {
    height: {
      type: Number,
      validator: (value) => value > 0,
    },
    chartData: {
      required: true,
    },
  },
  methods: {
    total(type, data) {
      let sum = 0;
      for (let i = 0; i < data[type].data.length; i++) {
        sum += Number(data[type].data[i]);
      }
      return Number(sum).toLocaleString(ChartUtils.language, {
        maximumFractionDigits: 2,
      });
    },
    getYearLabel(data) {
      return data.x[0].year;
    },
    getYearSeries(data, period, realTitle, predictedTitle) {
      data = data[period];
      for (let i = 0; i < data.real.length; i++) {
        let valReal = data.real[i];
        data.real[i] = valReal;
      }

      for (let i = 0; i < data.predict.length; i++) {
        let valPredict = data.predict[i];
        data.predict[i] = valPredict;
      }

      return [
        {
          name: realTitle,
          data: data.real,
        },
        {
          name: predictedTitle,
          data: data.predict,
        },
      ];
    },
  },
  computed: {
    thisYearLabel() {
      return this.getYearLabel(this.chartData["current"]);
    },
    lastYearLabel() {
      return this.getYearLabel(this.chartData["last"]);
    },
    lastYearChartOptions() {
      return ChartUtils.getBarChartOptions(
        this.chartData,
        "last",
        this.lastYearLabel
      );
    },
    thisYearChartOptions() {
      return ChartUtils.getBarChartOptions(
        this.chartData,
        "current",
        this.thisYearLabel
      );
    },
    thisYearSeries() {
      return this.getYearSeries(
        this.chartData,
        "current",
        this.$t("DASHBOARD.REAL"),
        this.$t("DASHBOARD.PREDICTED")
      );
    },
    lastYearSeries() {
      return this.getYearSeries(
        this.chartData,
        "last",
        this.$t("DASHBOARD.REAL"),
        this.$t("DASHBOARD.PREDICTED")
      );
    },
  },
};
</script>
