let language = localStorage.getItem("language");
language = language === "ro" ? "ro-RO" : "en-US";

const chartColors = ["#3699FF", "#8126fc", "#fd8226", "#757595"];

const colors = {
  "Power outage": "#fc2635", // red
  "Bad weather": "#fd8226", // orange
  Wind: "#8126fc", // purple
  Other: "#26fc81", // green
  Holiday: "#fc26a0", // pink
};

const ChartUtils = {
  getLanguage() {
    let language = localStorage.getItem("language");
    language = language === "ro" ? "ro-RO" : "en-US";
    return language;
  },
  total(type, data) {
    let sum = 0;
    for (let i = 0; i < data[type].data.length; i++) {
      sum += Number(data[type].data[i]);
    }
    return Number(sum).toLocaleString(this.getLanguage(), {
      maximumFractionDigits: 2,
    });
  },
  tooltip() {
    return {
      x: {
        formatter: (value, opts1, opts2) => {
          let chartData = opts1.w
            ? opts1.w.config.series[0].data
            : opts2.w.config.series[0].data;
          let correctTimestamp = this.getXLabel(chartData, value);
          if (!correctTimestamp) return "";
          return new Date(correctTimestamp).toLocaleString(language, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          });
        },
      },
      z: {
        formatter: function () {
          return "";
        },
        title: "",
      },
      y: {
        formatter: function (value) {
          return Number(value).toLocaleString() + " MW";
        },
      },
    };
  },
  getXLabel(chartData, timestamp) {
    for (let i = 0; i < chartData.length; i++) {
      if (chartData[i][0] == timestamp) {
        return chartData[i][2];
      }
    }
    // timestamp not found, so it should be between values
    for (let i = 0; i < chartData.length; i++) {
      if (chartData[i][0] > timestamp) {
        return chartData[i - 1][2];
      }
    }
  },
  xAxis() {
    return {
      type: "datetime",
      labels: {
        formatter: (_, timestamp, opts) => {
          let chartData = opts.w.config.series[0].data;
          // timestamp is the generated dummy one; the correct timestamp is charData[i][2]
          let correctTimestamp = this.getXLabel(chartData, timestamp);
          let label = !correctTimestamp
            ? ""
            : new Date(correctTimestamp).toLocaleString(language, {
                weekday: "short",
                month: "short",
                day: "numeric",
              });
          return label;
        },
        rotate: 0,
      },
      tooltip: {
        enabled: false,
      },
    };
  },

  getWeekends(chartData) {
    let points = [];
    for (let i = 0; i < chartData.length; i++) {
      let data = chartData[i];
      let day = new Date(data[2]).getDay();
      if (day == 6) {
        let stop = i + 1 == chartData.length ? i : i + 1;
        // saturday
        points.push({
          x: data[0],
          x2: chartData[stop][0],
          fillColor: "#a4acb3",
          opacity: 0.4,
        });
      }
    }
    return points;
  },
  parseEventsAnnotations(events, chartData) {
    if (!events) {
      return [];
    }
    let points = [];
    for (let i = 0; i < events.length; i++) {
      let value = events[i];
      if (!value.hasOwnProperty("x")) {
        value.x = value[0];
      }
      if (!value.hasOwnProperty("y")) {
        value.y = value[1];
      }
      if (!value.hasOwnProperty("type")) {
        value.type = value[2];
      }
      let x = new Date(value.x.year, value.x.month - 1, value.x.day).getTime();
      let y = value.y;
      for (let i = 0; i < chartData.length; i++) {
        if (chartData[i][2] == x) {
          x = chartData[i][0];
          break;
        }
      }
      points.push({
        x: x,
        y: y,
        marker: {
          size: 6,
          fillColor: "white",
          strokeColor: colors[value.type], // TODO: depending on type (value[2])
          radius: 2,
        },
        label: {
          borderColor: colors[value.type],
          style: {
            color: "#fff",
            background: colors[value.type],
          },
          text: value.type,
        },
      });
    }
    return points;
  },
  getLineChartOptions(
    id,
    group,
    title,
    chartData,
    events,
    min,
    max,
    showToolbar = true
  ) {
    return {
      chart: {
        id: id,
        group: group,
        title: {
          text: title,
        },
        type: "line",
        height: 160,
        toolbar: {
          show: showToolbar,
        },
      },
      xaxis: this.xAxis(),
      yaxis: {
        show: true,
        showAlways: true,
        title: {
          text: title,
        },
        min: min,
        max: max,
        labels: {
          formatter: function (value) {
            return Number(value).toLocaleString();
          },
        },
      },
      tooltip: this.tooltip(),
      annotations: {
        xaxis: this.getWeekends(chartData),
        points: this.parseEventsAnnotations(events, chartData),
      },
    };
  },
  getSolarChartOptions(id, group, title, chartData, events) {
    return {
      chart: {
        id: id,
        group: group,
        title: {
          text: title,
        },
        type: "line",
        height: 160,
      },
      colors: ["#FEC24D"],
      xaxis: this.xAxis(),
      yaxis: {
        show: true,
        showAlways: true,
        title: {
          text: title,
        },
      },
      tooltip: this.tooltip(),
      annotations: {
        points: this.getWeekends(chartData).concat(
          this.parseEventsAnnotations(events, chartData)
        ),
      },
    };
  },
  getLineChartOptionsAnnotation(title) {
    // not used
    return {
      // has annotations
      chart: {
        id: "current",
        group: "social",
        title: {
          text: title,
        },
        type: "line",
        height: 160,
      },
      xaxis: this.xAxis(),
      tooltip: this.tooltip(),
      annotations: {
        xaxis: [
          // separation line
          // {
          //   x: this.startAnnotation,
          //   x2: this.endAnnotation,
          //   fillColor: "#B3F7CA",
          //   opacity: 0.2,
          //   label: {
          //     borderColor: "#B3F7CA",
          //     style: {
          //       fontSize: "10px",
          //       color: "#fff",
          //       background: "#00E396",
          //     },
          //     offsetY: -10,
          //     text: "Predicted values",
          //   },
          // },
        ],
      },
    };
  },
  getBarCategories(data, period) {
    data = data[period];
    let abbrMonths = [];
    let months = data.x;
    for (let i = 0; i < months.length; i++) {
      abbrMonths.push(
        new Date(
          months[i].year,
          months[i].month - 1, // TODO check
          months[i].day
        ).toLocaleString(this.getLanguage(), {
          month: "short",
        })
      );
    }
    return abbrMonths;
  },
  getBarChartOptions(data, period, title) {
    return {
      chart: {
        type: "bar",
        title: {
          text: title,
        },
        toolbar: {
          show: false,
        },
      },
      colors: chartColors,
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: this.getBarCategories(data, period),
      },
      yaxis: {
        show: true,
        showAlways: true,
        title: {
          text: title,
        },
        labels: {
          formatter: function (val) {
            return Number(val).toLocaleString();
          },
        },
      },
      fill: {
        opacity: 1,
      },
    };
  },
};

export default ChartUtils;
