<template>
  <div class="d-flex justify-content-around">
    <div class="col">
      <!-- consume this year -->
      <apexchart
        type="line"
        :options="chartOptions"
        :series="series"
        class="w-100"
        :height="height"
      ></apexchart>
    </div>
    <table v-if="!solar" class="mt-10 col-2 table h-100 align-self-center">
      <thead>
        <tr>
          <th>{{ $t("ENERGY_MANAGEMENT.HEADER_TOTAL") }}</th>
          <th>{{ $t("ENERGY_MANAGEMENT.HEADER_VALUE") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ date1 }}</td>
          <td>{{ total(1) }}</td>
        </tr>
        <tr>
          <td>{{ date2 }}</td>
          <td>{{ total(2) }}</td>
        </tr>
        <tr>
          <td>{{ date3 }}</td>
          <td>{{ total(3) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ChartUtils from "./utils";

export default {
  name: "PredictionChartsDay",
  components: {},
  props: {
    height: {
      type: Number,
      validator: (value) => value > 0,
    },
    chartData: {
      required: true,
    },
    solar: {
      default: false,
    },
  },
  methods: {
    total(quarter) {
      let sum = 0;
      for (let i = (quarter - 1) * 24; i < quarter * 24; i++) {
        sum += Number(this.chartData.data[i][1]);
      }
      return Number(sum).toLocaleString(ChartUtils.language, {
        maximumFractionDigits: 2,
      });
    },
    shortDate(date) {
      return new Date(date).toLocaleString(ChartUtils.language, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
  },
  computed: {
    series() {
      return [{ name: this.chartData.name, data: this.chartData.data }];
    },
    date1() {
      return this.shortDate(this.chartData.data[0][0]);
    },
    date2() {
      return this.shortDate(this.chartData.data[24][0]);
    },
    date3() {
      return this.shortDate(this.chartData.data[48][0]);
    },
    chartOptions() {
      return {
        chart: {
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            autoSelected: "zoom",
            tools: {
              download: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val == null ? 0 : val.toFixed(2);
            },
          },
          title: {
            text: this.series.name,
          },
        },
        xaxis: {
          type: "datetime",
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return Number(val).toLocaleString() + " MW";
            },
          },
          x: {
            formatter: function (value) {
              const date = new Date(value);
              const timeDiff = date.getTimezoneOffset() * 60000;
              const adjustedDate = new Date(date.valueOf() + timeDiff);
              return adjustedDate.toLocaleDateString(ChartUtils.getLanguage(), {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              });
            },
          },
        },
      };
    },
  },
};
</script>
